import {Component, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-translation-button',
  templateUrl: './translation-button.component.html',
  styleUrls: ['./translation-button.component.scss']
})
export class TranslationButtonComponent implements OnInit {
  currentLanguage: string = 'nl';
  private translate: TranslateService;
  private subscriptions = new Subscription();
  selectorOpen: boolean = false;


  constructor(translate: TranslateService) {
    this.translate = translate
  }

  ngOnInit(): void {
    this.subscriptions.add(this.translate.onLangChange.subscribe(((event: LangChangeEvent) => {
        this.currentLanguage = event.lang
      }))
    )
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    this.selectorOpen = !this.selectorOpen;

  }

  openSelector() {
    this.selectorOpen = !this.selectorOpen;
  }
}
