<form (submit)="updateHangingHeight()" [formGroup]="form">
  <div class="modal__header">
    <div class="modal__header-text">
      <h2 class="modal__title">
        {{ 'products.product-settings-modal.title' | translate }}
      </h2>
    </div>

    <app-modal-close-button (onClose)="closeModal()"></app-modal-close-button>
  </div>

  <div class="modal__content">
    <div class="option__info">
      <p>{{ 'configurator.product-options-modal.product-hanging-height' | translate }}</p>
      <p class="text--grey">{{ 'configurator.product-options-modal.product-hanging-height-info' | translate }}</p>
    </div>
    <div class="flex gap-2 flex-col items-center">
      <div class="w-full">
        <div class="flex gap-2 items-center">
          <input class="w-full" formControlName="height" id="height" placeholder="Hoogte" type="number"/>
          <p>cm</p>
        </div>
        <p *ngIf="(form.controls['height'].touched || form.controls['height'].dirty) && form.controls['height'].invalid"
           class="error-message">
          {{ 'configurator.product-options-modal.product-hanging-height-error' | translate:errorParams }}
        </p>
      </div>
      <p>{{ 'shared.translations.or' | translate }}</p>
      <button (click)="setHeightToTotalHeight()" class="button button--outline w-full" type="button">
        {{ 'configurator.product-options-modal.hang-top' | translate }}
      </button>
    </div>

  </div>

  <div class="modal__actions">
    <button [disabled]="(form.dirty || form.touched) && form.invalid"
            class="button button--primary button--icon-text w-full">
      <span class="material-symbols-outlined">save</span>
      {{ 'configurator.product-options-modal.confirm' | translate }}
    </button>
  </div>

</form>
