import {Component, OnDestroy, OnInit} from '@angular/core';
import {PopUpService} from "../../pop-up.service";
import {PopUp} from "../../models/pop-up";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-pop-up-holder',
  templateUrl: './pop-up-holder.component.html',
  styleUrls: ['./pop-up-holder.component.scss']
})
export class PopUpHolderComponent implements OnInit, OnDestroy {
  popUps: PopUp[];

  private subscriptions = new Subscription();

  constructor(private popUpService: PopUpService) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.popUpService
        .getPopUps()
        .subscribe(popUps => {
          this.popUps = popUps
        })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
