<div (click)="closePopUp()" [attr.aria-expanded]="popUp.isOpen" [ngClass]="[popUp.popUpType.cssClass]" class="pop-up">
  <div class="pop-up__message">
    <h3 class="pop-up__title">{{popUp.title}}</h3>
    <p class="pop-up__text">{{popUp.message}}</p>
  </div>
  <button class="button button--outline button--round button--icon">
    <span class="material-symbols-outlined">
      close
    </span>
  </button>
</div>
