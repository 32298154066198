import {v4 as uuid} from "uuid";
import PopUpType from "./pop-up-type";

export class PopUp {
  id: string;
  popUpType: PopUpType;
  message: string;
  title: string;
  isOpen: boolean = true;

  constructor(popUpType: PopUpType, message: string, title?: string) {
    this.id = uuid();
    this.popUpType = popUpType;
    this.message = message;
    if (title) this.title = title;
  }
}
