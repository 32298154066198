import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ModalService} from "./modal.service";
import {ModalIdRequiredError} from "../custom-errors/modal-id-required.error";

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() modalClass: string | undefined;

  @Output() isClosing: EventEmitter<void> = new EventEmitter<void>();
  @Output() isOpening: EventEmitter<void> = new EventEmitter<void>();

  isOpened: boolean = false;

  private element: any;

  constructor(
    private modalService: ModalService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) throw new ModalIdRequiredError();

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', (el: any) => {
      if (el.target.classList.contains('modal__wrapper')) {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.isOpening.emit()
    this.isOpened = true;
  }

  // close modal
  close(): void {
    this.isClosing.emit();
    this.isOpened = false;
  }
}
