import {NgModule} from "@angular/core";
import {routing} from "./product.routes";
import {ProductCardComponent} from "./components/product-card/product-card.component";
import {AsyncPipe, CurrencyPipe, DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ProductInfoModalComponent} from './components/product-info-modal/product-info-modal.component';
import {AccessoryCardComponent} from './components/accessory-card/accessory-card.component';
import {ProductSettingsModalComponent} from './components/product-settings-modal/product-settings-modal.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    ProductCardComponent,
    ProductInfoModalComponent,
    AccessoryCardComponent,
    ProductSettingsModalComponent,
  ],
  exports: [
    ProductCardComponent,
    ProductInfoModalComponent,
    ProductSettingsModalComponent
  ],
    imports: [
        routing,
        NgForOf,
        NgIf,
        AsyncPipe,
        CurrencyPipe,
        DecimalPipe,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
    ]
})
export class ProductModule {
}
