import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {ConfiguratorState} from "../../configurator.state";
import {Observable, Subscription} from "rxjs";
import {Configuration} from "../../models/configuration";
import {Product} from "../../../products/models/product";
import {ProductStack} from "../../models/product-stack";
import {ProductLevel} from "../../models/product-level";
import {ResetConfiguration} from "../../configurator.actions";
import {ModalService} from "../../../shared/modal/modal.service";
import {TooltipPosition} from "../../../shared/tooltip/tooltip-position";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {
  @Select(ConfiguratorState.configuration) configuration$: Observable<Configuration>;

  configurationExpanded: boolean = false;

  products: Product[] = [];
  totalPrice: number = 0;

  confirmationModalId: string;
  protected readonly TooltipPosition = TooltipPosition;
  private subscriptions = new Subscription();

  constructor(
    private store: Store,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.confirmationModalId = 'resetConfigurationModal';
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  calculateTotalPriceForProduct(product: Product): number {
    let price: number = 0;
    if (product.selectedVariation) price += product.selectedVariation.price * product.numberInConfiguration;
    else price += product.price * product.numberInConfiguration;
    if (product.accessories && product.accessories.length > 0) price += product.accessories?.reduce((total, accessory) => total + (accessory.price * accessory.numberInConfiguration), 0)
    return price;
  }

  resetConfiguration() {
    this.store.dispatch(new ResetConfiguration())
  }

  openConfigurationModal() {
    this.modalService.open(this.confirmationModalId)
  }

  addToCart() {
    let url = 'https://wasombouw.nl/cart/?add-to-cart='
    this.products.forEach((product, index) => {
      if (index !== 0) url += ','

      if (!product.selectedVariation) url += `${product.id}:${product.numberInConfiguration}`;
      else url += `${product.selectedVariation.id}:${product.numberInConfiguration}`;

      product.accessories.forEach((accessory) => {
        if (accessory.numberInConfiguration > 0) {
          url += ','
          url += `${accessory.id}:${accessory.numberInConfiguration}`;
        }
      })
    })

    window.open(url, '_blank')
  }

  private fetchData(): void {
    this.subscriptions.add(this.configuration$.subscribe(
      (configuration) => {
        this.populateProducts(configuration)
      }
    ));
  }

  private populateProducts(configuration: Configuration): void {
    // Get all products and accessories that are in the configuration
    // A recursive function is used.
    this.products = [];
    this.totalPrice = 0;
    const productsToReduce: Product[] = []
    for (let i = 0; i < configuration.productStacks.length; i++) {
      const productStack: ProductStack = configuration.productStacks[i];

      productsToReduce.push(...this.getAllProductsFromStack(productStack));
    }

    productsToReduce.forEach(product => {
      if (product.selectedVariation) this.totalPrice += product.selectedVariation.price;
      else this.totalPrice += product.price
      if (product.accessories && product.accessories.length > 0) this.totalPrice += product.accessories?.reduce((total, accessory) => total + (accessory.price * accessory.numberInConfiguration), 0)

      const index = this.products.findIndex(existingProduct => {
        if (existingProduct.selectedVariation && product.selectedVariation) return existingProduct.id === product.id && existingProduct.selectedVariation.id === product.selectedVariation.id
        else return existingProduct.id === product.id
      });

      if (index < 0) {
        // Product is not added yet to the array of existingProducts
        const newProduct: Product = JSON.parse(JSON.stringify(product));
        newProduct.numberInConfiguration = 1
        this.products.push(newProduct)
      } else {
        // Product is already added, so numberInConfiguration is increased
        if (!this.products[index].numberInConfiguration) this.products[index].numberInConfiguration = 1;
        this.products[index].numberInConfiguration += 1;
        // Accessories are added from the new product to the existing product.
        this.products[index].accessories?.forEach(accessory => {
          accessory.numberInConfiguration += product.accessories.find(newAccessory => newAccessory.id === accessory.id)?.numberInConfiguration;
        })
      }
    })
  }

  private getAllProductsFromStack(productStack: ProductStack | undefined): Product[] {
    if (!productStack) return [];
    const products: Product[] = []
    for (let i = 0; i < productStack.productLevels.length; i++) {
      products.push(...this.getAllProductsFromLevel(productStack.productLevels[i]));
    }
    return products;
  }

  private getAllProductsFromLevel(productLevel: ProductLevel): Product[] {
    if (productLevel.product) return [productLevel.product]
    if (!productLevel.productStacks) return [];
    const products: Product[] = []
    for (let i = 0; i < productLevel.productStacks.length; i++) {
      products.push(...this.getAllProductsFromStack(productLevel.productStacks[i]));
    }

    return products;
  }
}
