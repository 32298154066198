<button (click)="openSelector()" class="button button--outline button--icon-text-icon">
  <span class="material-symbols-outlined">translate</span>
  {{'shared.translation-button.instruction' | translate}}
  <span class="text--grey">&nbsp;({{currentLanguage}})</span>
  <span class="material-symbols-outlined" [ngClass]="{'rotate-180': selectorOpen}">expand_more</span>
</button>

<div *ngIf="selectorOpen" class="translation-button__container">
  <button (click)="changeLanguage('nl')">Nederlands</button>
  <button (click)="changeLanguage('de')">Deutsch</button>
</div>

