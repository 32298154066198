import {Component, Input} from '@angular/core';
import {ModalService} from "../../modal/modal.service";

@Component({
  selector: 'app-usp-modal',
  templateUrl: './usp-modal.component.html',
  styleUrls: ['./usp-modal.component.scss']
})
export class UspModalComponent {
  @Input() modalId: string;

  constructor(
    private modalService: ModalService,
  ) {
  }

  closeModal() {
    this.modalService.close(this.modalId);
  }
}
