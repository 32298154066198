import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-modal-close-button',
  templateUrl: './modal-close-button.component.html',
  styleUrls: ['./modal-close-button.component.scss']
})
export class ModalCloseButtonComponent {
  @Output() onClose = new EventEmitter<void>();

  onCloseClick() {
    this.onClose.emit();
  }
}
