import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalIdRequiredError} from "../../custom-errors/modal-id-required.error";
import {ModalService} from "../../modal/modal.service";

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() text: string;
  @Input() modalId: string;

  @Output() confirm = new EventEmitter<void>()

  constructor(private modalService: ModalService) {
  }

  ngOnInit(): void {
    if (!this.modalId) throw new ModalIdRequiredError();
  }

  closeModal() {
    this.modalService.close(this.modalId);
  }

  onConfirm() {
    this.confirm.emit();
    this.closeModal();
  }
}
