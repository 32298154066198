<div class="modal__header">
  <div class="modal__header-text">
    <h2 class="modal__title">{{ title }}</h2>
    <p *ngIf="subtitle" class="modal__subtitle">{{ subtitle }}</p>
  </div>
  <app-modal-close-button (onClose)="closeModal()"></app-modal-close-button>
</div>
<div class="modal__content">
  <p *ngIf="text">{{ text }}</p>
</div>
<div class="modal__actions">
  <button (click)="closeModal()" class="button button--outline">
    {{ 'shared.translations.cancel' | translate }}
  </button>
  <button (click)="onConfirm()" class="button button--outline button--warn">
    {{ 'shared.translations.proceed' | translate }}
  </button>
</div>
