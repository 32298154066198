import {Component, Input, OnInit} from '@angular/core';
import {PopUp} from "../../models/pop-up";
import {PopUpService} from "../../pop-up.service";

@Component({
  selector: 'app-pop-up-message',
  templateUrl: './pop-up-message.component.html',
  styleUrls: ['./pop-up-message.component.scss']
})
export class PopUpMessageComponent implements OnInit {

  @Input() popUp: PopUp;

  constructor(private popUpService: PopUpService) {
  }

  ngOnInit() {
    setInterval(() => {
      this.closePopUp();
    }, 5000)
  }

  closePopUp() {
    this.popUp.isOpen = false;
    this.popUpService.close(this.popUp)
  }
}
