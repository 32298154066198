import {Component, Input, OnInit} from '@angular/core';
import {ModalIdRequiredError} from "../../../shared/custom-errors/modal-id-required.error";
import {ModalService} from "../../../shared/modal/modal.service";
import {Configuration} from "../../models/configuration";
import {Select, Store} from "@ngxs/store";
import {
  FetchAllRoomProperties,
  UpdateFloorProperty,
  UpdateFloorType,
  UpdateRoomDimensions, UpdateWallProperty
} from "../../configurator.actions";
import {Observable, Subscription} from "rxjs";
import {ConfiguratorState} from "../../configurator.state";
import {RoomProperty} from "../../models/room-property";
import {PopUp} from "../../../shared/pop-up/models/pop-up";
import PopUpType from "../../../shared/pop-up/models/pop-up-type";
import PopUpMessage from "../../../shared/pop-up/models/pop-up-message";
import {PopUpService} from "../../../shared/pop-up/pop-up.service";
import {FloorProperty} from "../../models/floor-property";
import {WallProperty} from "../../models/wall-property";

@Component({
  selector: 'app-edit-room-modal',
  templateUrl: './edit-room-modal.component.html',
  styleUrls: ['./edit-room-modal.component.scss']
})
export class EditRoomModalComponent implements OnInit {
  @Input() modalId: string;
  @Input() configuration: Configuration;

  @Select(ConfiguratorState.roomProperties) roomProperties$: Observable<RoomProperty>;
  roomProperties: RoomProperty;
  fetchedRoomProperties: boolean = false;
  roomPropertiesRequestFailed: boolean = false;

  selectedFloorProperty: FloorProperty;
  selectedWallProperty: WallProperty;
  roomWidth: number;
  roomHeight: number;

  private subscriptions = new Subscription();

  constructor(
    private modalService: ModalService,
    private store: Store,
    private popUpService: PopUpService,
  ) {
  }

  ngOnInit(): void {
    if (!this.modalId) throw new ModalIdRequiredError();

    this.fetchData()
  }

  closeModal() {
    this.modalService.close(this.modalId);
  }

  updateFloorProperty() {
    this.store.dispatch(new UpdateFloorProperty(this.selectedFloorProperty));
  }

  updateWallProperty() {
    this.store.dispatch(new UpdateWallProperty(this.selectedWallProperty));
  }

  updateRoomDimensions() {
    this.store.dispatch(new UpdateRoomDimensions({width: this.roomWidth, height: this.roomHeight}));
  }

  private fetchData(): void {
    this.subscriptions.add(this.roomProperties$.subscribe((roomProperties: RoomProperty) => {
      this.roomProperties = roomProperties;
      this.selectedFloorProperty = this.roomProperties?.floorProperties[0];
      this.selectedWallProperty = this.roomProperties?.wallProperties[0];

      if (!roomProperties && !this.fetchedRoomProperties) {
        this.fetchedRoomProperties = true;
        this.subscriptions.add(
          this.store.dispatch(new FetchAllRoomProperties())
            .subscribe({
              next: () => {
                this.roomPropertiesRequestFailed = false;
              },
              error: (err) => {
                this.roomPropertiesRequestFailed = true;
                this.popUpService.add(new PopUp(PopUpType.ERROR, PopUpMessage.LOADING_PRODUCTS_PROBLEM.message, 'Woeps, iets is misgelopen...'))
              }
            }));
        return;
      }
    }))
  }
}
