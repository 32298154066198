import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ChangeColorOfProducts} from "../../configurator.actions";
import {Select, Store} from "@ngxs/store";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {ConfiguratorState} from "../../configurator.state";
import {checkIfAllProductsHaveSameColorInConfiguration, Configuration} from "../../models/configuration";
import {ProductProperty} from "../../models/product-property";
import {ColorProperty} from "../../../products/models/color-property";
import {Product} from "../../../products/models/product";
import {ProductState} from "../../../products/product.state";

@Component({
  selector: 'app-color-switch-buttons',
  templateUrl: './color-switch-buttons.component.html',
  styleUrls: ['./color-switch-buttons.component.scss']
})
export class ColorSwitchButtonsComponent implements OnInit, OnDestroy {
  @Select(ConfiguratorState.configuration) configuration$: Observable<Configuration>;

  @Input() products: Product[];

  @Select(ProductState.productProperties) productProperties$: Observable<ProductProperty>
  productProperties: ProductProperty;

  form: FormGroup;

  private subscriptions = new Subscription();

  constructor(
    private store: Store,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateColor(colorId: string) {
    this.store.dispatch(new ChangeColorOfProducts(colorId, this.products))
  }

  private fetchData(): void {
    this.subscriptions.add(this.configuration$.subscribe(
      (configuration) => {
        let selectedColor: ColorProperty;
        if (configuration.productStacks.length > 0 && checkIfAllProductsHaveSameColorInConfiguration(configuration)) {
          selectedColor = configuration.productStacks[0].productLevels[configuration.productStacks[0].productLevels.length - 1].product.selectedVariation.colorProperty;
        }
        this.createForm(selectedColor);
      }));

    this.subscriptions.add(this.productProperties$.subscribe((productProperties) => {
      this.productProperties = productProperties;
    }))
  }

  private createForm(selectedColor: ColorProperty) {
    if (!this.form) {
      this.form = this.fb.group({
        selectedColor: selectedColor
      });
      return;
    }

    if (this.form.get('selectedColor').value?.id === selectedColor?.id) return;
    this.form.get('selectedColor').setValue(selectedColor);
  }
}
