import { Pipe, PipeTransform } from '@angular/core';
import {Category} from "../../products/models/category";

@Pipe({
  name: 'filterCategories'
})
export class FilterCategoriesPipe implements PipeTransform {

  transform(categories: Category[]): Category[] {
    return categories.filter(category =>  category.id !== 193); // Do not display accessories
  }

}
