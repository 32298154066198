import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ModalService} from "../../../shared/modal/modal.service";
import {ModalIdRequiredError} from "../../../shared/custom-errors/modal-id-required.error";
import {Side} from "../../models/side";
import {ProductVariation} from "../../../products/models/product-variation";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Product} from "../../../products/models/product";
import {Select} from "@ngxs/store";
import {ConfiguratorState} from "../../configurator.state";
import {min, Observable, Subscription} from "rxjs";
import {ColorProperty} from "../../../products/models/color-property";

@Component({
  selector: 'app-product-options-modal',
  templateUrl: './product-options-modal.component.html',
  styleUrls: ['./product-options-modal.component.scss']
})
export class ProductOptionsModalComponent implements OnInit, OnChanges, OnDestroy {
  @Select(ConfiguratorState.totalHeight) totalHeight$: Observable<number>;
  totalHeight: number;
  minHeight: number;

  @Input() modalId: string;
  @Input() sideNeeded: boolean = false;
  @Input() product: Product;

  @Output() optionsSelected = new EventEmitter<ProductOptions>();

  availableColorProperties: ColorProperty[];
  availableProductHeights: number[];

  optionsForm: FormGroup;
  errorParams = {value: 0};
  protected readonly PositionSwitchDirections = Side;
  private subscriptions = new Subscription();

  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
  ) {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.modalId) throw new ModalIdRequiredError();
    this.fetchData();
    this.createForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
  }

  closeModal() {
    this.modalService.close(this.modalId);
  }

  onOptionsSubmit() {
    this.closeModal();

    const selectedVariation: ProductVariation = this.product.variations.find((variation) => {
      if (this.availableProductHeights.length === 0) return variation.colorProperty?.id === this.optionsForm.get('selectedColorProperty').value.id;
      if (this.availableProductHeights.length === 0) return variation.height == this.optionsForm.get('selectedProductHeight').value;
      return variation.colorProperty?.id === this.optionsForm.get('selectedColorProperty').value.id && variation.height == this.optionsForm.get('selectedProductHeight').value
    })
    let imgUrl: string = this.optionsForm.value.assembly === Side.LEFT ? this.product.imgUrl : this.product.alternativeImgUrl;
    if (selectedVariation) imgUrl = this.optionsForm.value.assembly === Side.LEFT ? selectedVariation.imgUrl : selectedVariation.alternativeImgUrl;

    this.optionsSelected.emit({
      variation: selectedVariation,
      side: this.optionsForm.value.side,
      height: this.optionsForm.value.height,
      imgUrl: imgUrl,
      assembleSide: this.optionsForm.value.assembly
    })
  }

  setHeightToTotalHeight() {
    this.optionsForm.controls['height'].setValue(Math.max(this.totalHeight, this.product?.height));
  }

  private createForm() {
    this.availableProductHeights = this.product?.variations?.reduce((array, item) => {
      if (item.height && !array.find(a => a === item.height)) {
        array.push(item.height)
      }
      return array;
    }, []);
    this.availableColorProperties = this.product?.variations?.reduce((array, item) => {
      if (item.colorProperty && !array.find(a => a.id === item.colorProperty.id)) {
        array.push(item.colorProperty)
      }
      return array;
    }, []);

    let height: number = Math.max(this.totalHeight, this.product?.height)
    this.errorParams.value = height;
    let defaultHeight: number = height;

    if (this.totalHeight === 0 || this.totalHeight < 205) {
      if (this.product?.height === 90) defaultHeight = 240
      else defaultHeight = 205
    }

    this.optionsForm = this.fb.group({
      selectedVariation: this.product && this.product.variations && this.product.variations[0],
      side: Side.LEFT,
      height: [defaultHeight, Validators.min(height)],
      assembly: Side.LEFT,
      selectedColorProperty: this.availableColorProperties?.at(0),
      selectedProductHeight: this.availableProductHeights?.at(0)
    })
  }

  private fetchData() {
    this.subscriptions.add(
      this.totalHeight$.subscribe(totalHeight => this.totalHeight = totalHeight)
    )
  }

  getImageURLForAvailableHeight(availableHeight: number): string {
    return this.product?.variations.find((variation) =>
      variation.colorProperty?.id === this.optionsForm.get('selectedColorProperty').value.id && variation.height === availableHeight
    ).imgUrl;
  }

  getImgUrl(side: Side): string {
    const variation = this.product.variations.find((variation) => {
      if (!this.optionsForm.get('selectedProductHeight').value) return variation.colorProperty?.id === this.optionsForm.get('selectedColorProperty').value.id
      return variation.height === this.optionsForm.get('selectedProductHeight').value && variation.colorProperty?.id === this.optionsForm.get('selectedColorProperty').value.id
    })
    if (side === Side.LEFT) return variation.imgUrl;
    return variation.alternativeImgUrl;
  }
}

export interface ProductOptions {
  side?: Side;
  variation?: ProductVariation;
  height?: number;
  imgUrl?: string;
  assembleSide?: Side;
}
