import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Accessory} from "../../models/accessory";
import {Store} from "@ngxs/store";
import {Product} from "../../models/product";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-accessory-card',
  templateUrl: './accessory-card.component.html',
  styleUrls: ['./accessory-card.component.scss']
})
export class AccessoryCardComponent implements OnInit {
  @Input() accessory: Accessory;
  @Input() product: Product;
  @Input() form: FormGroup;

  numberInConfiguration: number;
  @Output() numberInConfigurationChange = new EventEmitter<number>();

  constructor(private store: Store) {
  }

  ngOnInit() {
    this.numberInConfiguration = this.accessory.numberInConfiguration;
  }

  addAccessory() {
    this.form.controls[`${this.accessory.id}-number`].setValue(this.form.controls[`${this.accessory.id}-number`].value + 1)
  }

  removeAccessory() {
    this.form.controls[`${this.accessory.id}-number`].setValue(this.form.controls[`${this.accessory.id}-number`].value - 1)
  }

  removeButtonIsDisabled(): boolean {
    return this.form.controls[`${this.accessory.id}-number`].value < 1;
  }
}
