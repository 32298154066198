import {RouterModule, Routes} from "@angular/router";
import {ModuleWithProviders} from "@angular/core";
import {ConfiguratorComponent} from "./components/configurator/configurator.component";
import {StartScreenComponent} from "./components/start-screen/start-screen.component";

export const routes: Routes = [
  {
    path: '',
    component: StartScreenComponent
  },
  {
    path: 'start',
    pathMatch: 'prefix',
    redirectTo: ''
  },
  {
    path: "configurator",
    component: ConfiguratorComponent
  }
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
