import {Product} from "./models/product";
import Color from "./models/colors";
import {Category} from "./models/category";
import {ProductStack} from "../configurator/models/product-stack";
import {ProductProperty} from "../configurator/models/product-property";

export class FetchAllProducts {
  static readonly type = '[Product] FetchAll'
}

export class FetchAllCategories {
  static readonly type = '[Category] FetchAll'
}

export class FetchAllColorProperties {
  static readonly type = '[ColorProperty] FetchAll'
}

export class SetColorProperties {
  static readonly type = '[ColorProperty] Set'

  constructor(public productProperties: ProductProperty) {
  }
}

export class GetNumberOfProductsForEachCategory {
  static readonly type = '[Category] GetNumberOfProducts';

  constructor(public products: Product[], public search: string, public color: Color, public selectedCategory: Category, public stack?: ProductStack) {
  }
}

export class UpdateSelectedVariation {
  static readonly type = '[ProductVariant] UpdateSelectedVariation';

  constructor(public variationId: number, public productId: number) {
  }
}
