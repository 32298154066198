<div [formGroup]="form" class="list__item-container">
  <div class="list__item-img">
    <img
      [alt]="accessory.title"
      [src]="accessory.imgUrl"
      onerror="this.src='assets/images/fallback.png'"
    >
  </div>

  <div class="list__item-content">
    <div class="list__item-info">
      <h3 class="card__title">{{accessory.title}}</h3>
      <p class="price">
        <span class="euro">€</span>
        <span class="price">
        {{accessory.price}}
      </span>
      </p>
    </div>
    <div class="list__item-buttons">
      <button (click)="removeAccessory()" [disabled]="removeButtonIsDisabled()"
              class="button button--icon button--primary"
              type="button">
      <span class="material-symbols-outlined">
        remove
      </span>
      </button>

      <input
        [formControlName]="accessory.id + '-number'"
        class="input--number"
        type="number">

      <button (click)="addAccessory()" class="button button--icon button--primary" type="button">
      <span class="material-symbols-outlined">
        add
      </span>
      </button>
    </div>
  </div>

</div>
