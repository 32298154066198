import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import localeNl from '@angular/common/locales/nl'
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxsModule} from "@ngxs/store";
import {environment} from "../environments/environment";
import {ProductModule} from "./products/product.module";
import {ProductState} from "./products/product.state";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {ConfiguratorModule} from "./configurator/configurator.module";
import {SharedModule} from "./shared/shared.module";
import {ConfiguratorState} from "./configurator/configurator.state";
import {registerLocaleData} from "@angular/common";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(localeNl)

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

    ConfiguratorModule,
    ProductModule,
    SharedModule,

    NgxsModule.forRoot([
        ConfiguratorState,
        ProductState,
      ],
      {
        developmentMode: !environment.production
      }),

    TranslateModule.forRoot({
      defaultLanguage: 'nl',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'nl'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
