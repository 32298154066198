export default class FloorType {
  static readonly GREY = new FloorType('Grijs', 'default')
  static readonly HERRINGBONE = new FloorType('Visgraat', 'assets/floor-types/herringbone.jpg', true)
  static readonly CONCRETE = new FloorType('Beton', 'assets/floor-types/concrete.jpg')
  static readonly ASH = new FloorType('Es', 'assets/floor-types/ash.jpg')
  static readonly TERRAZZO = new FloorType('Terrazzo', 'assets/floor-types/terrazzo.jpg')
  static readonly TILES = new FloorType('Tegels', 'assets/floor-types/tiles.jpg')
  static readonly WALNUT = new FloorType('Walnoot', 'assets/floor-types/walnut.jpg', true)

  private constructor(public readonly type: string, public readonly imgUrl: string, public readonly isDark: boolean = false) {
  }
}
