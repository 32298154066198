import {Component, Input, OnInit} from '@angular/core';
import {Product} from "../../models/product";
import {Store} from "@ngxs/store";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() product: Product;
  productHeight: string;

  constructor() {
  }

  ngOnInit(): void {
    const availableProductHeights = this.product?.variations?.reduce((array, item) => {
      if (item.height && !array.find(a => a === item.height)) {
        array.push(item.height)
      }
      return array;
    }, []);
    if (availableProductHeights.length > 1) this.productHeight = availableProductHeights.reduce((string, height) => string + '|' + height)
    else if (availableProductHeights.length > 0) this.productHeight = availableProductHeights[0];
    else this.productHeight = `${this.product.height}`;
  }
}
