import {v4 as uuid} from "uuid";
import {
  checkIfAllProductsHaveSameColorInProductStack,
  getLevelFromStack,
  getStackFromStack,
  getTotalHeightBelowProductInProductStack,
  ProductStack
} from "./product-stack";
import {ProductLevel} from "./product-level";
import FloorType from "./floor-type";
import Color from "../../products/models/colors";
import {RoomDimensions} from "./room-dimensions";
import {FloorProperty} from "./floor-property";
import {WallProperty} from "./wall-property";
import {ColorProperty} from "../../products/models/color-property";

export class Configuration {
  productStacks: ProductStack[];
  floorType: FloorType;
  selectedFloorProperty: FloorProperty;
  selectedWallProperty: WallProperty;
  roomDimensions: RoomDimensions;

  constructor(productStacks?: ProductStack[]) {
    if (productStacks) this.productStacks = productStacks;
    else this.productStacks = [];

    this.floorType = FloorType.GREY;
    this.selectedFloorProperty = {name: 'Standaard', image: 'default', id: uuid()}
    this.selectedWallProperty = {name: 'Standaard', image: 'default', id: uuid()}
  }
}

// Recursive can't be placed in the class because after JSON.parse(JSON.stringify()) the methods aren't copied to the new object.

export function getStackFromConfiguration(configuration: Configuration, stackId: string): ProductStack | undefined {
  if (configuration.productStacks.length < 1) return;
  for (let i = 0; i < configuration.productStacks.length; i++) {
    const stack = getStackFromStack(configuration.productStacks[i], stackId)
    if (stack) return stack;
  }
  return;
}

export function getLevelFromConfiguration(configuration: Configuration, levelId: string): ProductLevel | undefined {
  if (configuration.productStacks.length < 1) return;
  for (let i = 0; i < configuration.productStacks.length; i++) {
    const level = getLevelFromStack(configuration.productStacks[i], levelId)
    if (level) return level;
  }
  return;
}

export function getRemainingSpaceForLevel(productStack: ProductStack): number {
  // Checks if there is place to add a new stack in the remaining space of the level, when false the add button is not rendered.
  // A recursive function is needed
  if (productStack.productLevels[0].product || !productStack.productLevels[0].productStacks) return;
  const previousLevel = productStack.productLevels[1];
  if (!previousLevel.product) return;

  let alreadyStackedWidth = 0;
  productStack.productLevels[0].productStacks.forEach(newStack => {
      if (newStack && newStack.productLevels[0].product)
        alreadyStackedWidth += newStack.productLevels[0].product.width
    }
  )

  return previousLevel.product.width - alreadyStackedWidth;
}

export function getTotalHeightBelowProductInConfiguration(configuration: Configuration, productConfigurationId: string) {
  const calculateHeightBelowProductInConfiguration: CalculateHeightBelowProduct = {
    productFound: false,
    height: 0
  }

  for (let i = 0; i < configuration?.productStacks?.length; i++) {
    const productStack = configuration.productStacks[i];
    calculateHeightBelowProductInConfiguration.height = 0;
    const heightBelowProductInProductStack = getTotalHeightBelowProductInProductStack(productStack, productConfigurationId, calculateHeightBelowProductInConfiguration)
    if (heightBelowProductInProductStack.productFound) return heightBelowProductInProductStack.height;
  }

  return 0;
}

export interface CalculateHeightBelowProduct {
  productFound: boolean,
  height: number,
}

export function checkIfAllProductsHaveSameColorInConfiguration(configuration: Configuration): boolean {
  let colorProperty: ColorProperty = configuration.productStacks[0].productLevels[configuration.productStacks[0].productLevels.length - 1].product.selectedVariation.colorProperty;
  for (let i = 0; i < configuration.productStacks.length; i++) {
    const isSameColor = checkIfAllProductsHaveSameColorInProductStack(configuration.productStacks[i], colorProperty);
    if (!isSameColor) return false;
  }
  return true;
}
