import {Injectable} from "@angular/core";
import {PopUp} from "./models/pop-up";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class PopUpService {
  private popUps: PopUp[] = []
  private popUps$: BehaviorSubject<PopUp[]> = new BehaviorSubject<PopUp[]>(this.popUps);

  add(popUp: PopUp) {
    this.popUps.push(popUp)
    this.popUps$.next(this.popUps)
  }

  close(popUpToClose: PopUp) {
    setInterval(() => {

      this.popUps = this.popUps.filter(popUp => popUp.id !== popUpToClose.id);
      this.popUps$.next(this.popUps)
    }, 200)
  }

  getPopUps(): Observable<PopUp[]> {
    return this.popUps$.asObservable();
  }
}
