import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Category} from "../../../products/models/category";
import {Select, Store} from "@ngxs/store";
import {Observable, Subscription} from "rxjs";
import {ConfiguratorState} from "../../configurator.state";
import {UpdateSelectedCategory} from "../../configurator.actions";
import {ProductState} from "../../../products/product.state";

@Component({
  selector: 'app-category-chooser',
  templateUrl: './category-chooser.component.html',
  styleUrls: ['./category-chooser.component.scss']
})
export class CategoryChooserComponent implements OnInit, OnDestroy {
  @Select(ProductState.categories) categories$: Observable<Category[]>;
  categories: Category[]

  @Select(ConfiguratorState.selectedCategory) selectedCategory$: Observable<Category>;
  selectedCategory: Category;

  private subscriptions = new Subscription();

  constructor(private store: Store) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.fetchData();
  }


  private fetchData() {
    this.subscriptions.add(
      this.selectedCategory$.subscribe((c) => this.selectedCategory = c)
    );

    this.subscriptions.add(
      this.categories$.subscribe((c) => this.categories = c)
    )
  }

  updateSelectedCategory(category: Category) {
    this.store.dispatch(new UpdateSelectedCategory(category))
  }
}
