import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, Observable} from "rxjs";
import {RoomProperty} from "./models/room-property";
import {v4 as uuid} from "uuid";

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorService {
  private readonly wooCommerceUrl: string;

  constructor(private httpClient: HttpClient) {
    this.wooCommerceUrl = `${environment.wooCommerceUrl}/configurator`;
  }

  fetchAllRoomProperties(): Observable<RoomProperty> {
    return this.httpClient.get<any>(
      `${this.wooCommerceUrl}/room-properties`
    ).pipe(
      map((data) => {
        const roomProperty: RoomProperty = {
          floorProperties: [{name: 'Standaard', image: 'default', id: uuid()}, ...data.floors],
          wallProperties: [{name: 'Standaard', image: 'default', id: uuid()}, ...data.walls]
        }
        roomProperty.floorProperties.forEach((floorProperty) => {
          floorProperty.id = uuid()
        })
        roomProperty.wallProperties.forEach((wallProperties) => {
          wallProperties.id = uuid()
        })
        return roomProperty;
      })
    )
  }
}
