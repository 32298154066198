import {Product} from "../../products/models/product";
import Color from "../../products/models/colors";
import {capitalizeFirstLetter} from "./string.helpers";
import {ProductVariation} from "../../products/models/product-variation";
import {Accessory} from "../../products/models/accessory";
import {Category} from "../../products/models/category";
import {ProductStack} from "../../configurator/models/product-stack";

export function createProductImgUrl(product: Product): string {
  const color: Color = product.color;
  return `${product.title.trim().replaceAll(' ', '')}_${capitalizeFirstLetter(color.value)}_${product.height}`;
}

export function createVariationImgUrl(productVariation: ProductVariation, product: Product): string {
  const color: Color = product.color;
  return `${product.title.trim().replaceAll(' ', '')}_${capitalizeFirstLetter(color.value)}_${productVariation.height}`;
}

export function createAccessoryImgUrl(accessory: Accessory): string {
  const color: Color = accessory.color;
  if (!color) return;
  return `${accessory.title.trim().replaceAll(' ', '')}_${capitalizeFirstLetter(color.value)}`;
}

export function filterProductsForEachCategory(products: Product[], search: string, color: Color, selectedCategory: Category, stack?: ProductStack): Product[] {
  if (!products || products.length < 1) return products
  if (selectedCategory && selectedCategory.id !== -1) products = products.filter(p => p.categories.find(c => c.id === selectedCategory.id))
  if (!stack) return products.filter(product => {
    if (color) return product.title.toLowerCase().includes(search?.toLowerCase()) && product.color === color;
    else return product.title.toLowerCase().includes(search?.toLowerCase())
  });

  const lastProduct: Product | undefined = stack.productLevels[stack.productLevels.length - 1].product
  if (!lastProduct) return products;
  const width = lastProduct.width;

  if (!stack.productLevels[0] || !stack.productLevels[0].productStacks || stack.productLevels[0].productStacks.length < 1)
    return products.filter(product => {
      if (color) return product.width <= width && product.isHangingCloset && product.title.toLowerCase().includes(search.toLowerCase()) && product.color === color;
      else return product.width <= width && product.isHangingCloset && product.title.toLowerCase().includes(search.toLowerCase())
    });
  let alreadyStackedWidth = 0;
  stack.productLevels[0].productStacks.forEach(newStack => {
      if (newStack && newStack.productLevels[0].product)
        alreadyStackedWidth += newStack.productLevels[0].product.width
    }
  )

  return products.filter(product => {
    if (color) return product.width <= width - alreadyStackedWidth && product.isHangingCloset && product.title.toLowerCase().includes(search.toLowerCase()) && product.color === color;
    else return product.width <= width - alreadyStackedWidth && product.isHangingCloset && product.title.toLowerCase().includes(search.toLowerCase());
  });
}
