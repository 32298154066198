import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {getHeightFromStack, ProductStack} from "../../models/product-stack";
import {ModalService} from "../../../shared/modal/modal.service";
import {Side} from "../../models/side";
import {Select, Store} from "@ngxs/store";
import {DuplicateProductStack, SwitchProductStackPosition} from "../../configurator.actions";
import {ProductLevel} from "../../models/product-level";
import {ProductState} from "../../../products/product.state";
import {Observable, Subscription} from "rxjs";
import {Configuration, getRemainingSpaceForLevel} from "../../models/configuration";
import {TooltipPosition} from "../../../shared/tooltip/tooltip-position";
import {ConfiguratorState} from "../../configurator.state";
import {AddProductModalComponent} from "../add-product-modal/add-product-modal.component";

@Component({
  selector: 'app-configurator-product-stack',
  templateUrl: './configurator-product-stack.component.html',
  styleUrls: ['./configurator-product-stack.component.scss']
})
export class ConfiguratorProductStackComponent implements OnInit, OnDestroy {
  @Input() productStack: ProductStack;
  @Input() isBottomStack: boolean = false;
  @Input() isFirstStack: boolean = false;
  @Input() isLastStack: boolean = false;
  @Input() totalStackHeightWithoutHangingProducts: number;

  @Select(ProductState.smallestProductWidth) smallestProductWidth$: Observable<number>;
  @Select(ConfiguratorState.configuration) configuration$: Observable<Configuration>;
  @Select(ConfiguratorState.multiplyFactor) multiplyFactor$: Observable<number>
  configuration: Configuration;
  multiplyFactor: number = 2;

  @ViewChild("addProductModalComponent") addProductModal: AddProductModalComponent;

  smallestProductWidth: number;
  selectedLevel: ProductLevel | undefined;
  placeForExtraProduct: boolean;
  addProductModalOpen: boolean = false;
  stackWidth: number;

  protected readonly PositionSwitchDirections = Side;
  protected readonly TooltipPosition = TooltipPosition;
  private subscriptions = new Subscription();
  moreOpened: boolean = false;

  @ViewChild('iconsMobile') iconsMobile: any;

  constructor(
    private modalService: ModalService,
    private store: Store,
    private _elementRef: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    this.fetchData();
    this.placeForExtraProduct = getRemainingSpaceForLevel(this.productStack) >= this.smallestProductWidth;
    this.stackWidth = this.productStack.productLevels[this.productStack.productLevels.length - 1].product.width;

    if (this.isBottomStack) {
      this.totalStackHeightWithoutHangingProducts = getHeightFromStack(this.productStack, this.configuration).heightForHangingProducts;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.iconsMobile?.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.moreOpened = false;
    }
  }

  onAddProduct(level?: ProductLevel) {
    this.selectedLevel = level;

    this.modalService.open(`configuratorProductStack-addProduct-${this.productStack.id}`);
    this.addProductModal.focusOnSearchbar()
  }

  onPositionClick(direction: Side): void {
    this.moreOpened = false;
    this.store.dispatch(new SwitchProductStackPosition(direction, this.productStack.id));
  }

  duplicateProductStack() {
    this.moreOpened = false;
    this.store.dispatch(new DuplicateProductStack(this.productStack.id))
  }

  private fetchData() {
    this.subscriptions.add(
      this.smallestProductWidth$.subscribe(width => {
        this.smallestProductWidth = width;
      })
    );
    this.subscriptions.add(
      this.configuration$.subscribe(configuration => {
        this.configuration = configuration;
      })
    );
    this.subscriptions.add(this.multiplyFactor$.subscribe(factor => {
      this.multiplyFactor = factor;
    }));
  }

  openMenu() {
    this.moreOpened = !this.moreOpened;
  }
}
