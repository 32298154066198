import {Action, Selector, State, StateContext, StateToken, Store} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {Product} from "./models/product";
import {ProductService} from "./product.service";
import {
  FetchAllCategories, FetchAllColorProperties,
  FetchAllProducts,
  GetNumberOfProductsForEachCategory, SetColorProperties,
  UpdateSelectedVariation
} from "./product.actions";
import {tap} from "rxjs";
import {patch, updateItem} from "@ngxs/store/operators";
import {ProductVariation} from "./models/product-variation";
import {environment} from "../../environments/environment";
import {Category} from "./models/category";
import {filterProductsForEachCategory} from "../shared/helpers/product.helpers";
import {UpdateSelectedCategory} from "../configurator/configurator.actions";
import {data} from "autoprefixer";
import {ColorProperty} from "./models/color-property";
import {ProductProperty} from "../configurator/models/product-property";

const PRODUCT_STATE_TOKEN = new StateToken<ProductStateModel>('product');

export interface ProductStateModel {
  products: Product[],
  categories: Category[],
  productProperties: ProductProperty;
}

@State<ProductStateModel>({
  name: PRODUCT_STATE_TOKEN,
  defaults: {
    products: null,
    categories: null,
    productProperties: null,
  }
})
@Injectable()
export class ProductState {
  constructor(private productService: ProductService, private store: Store) {
  }

  @Selector()
  static products(state: ProductStateModel): Product[] {
    return state?.products;
  }

  @Selector()
  static productProperties(state: ProductStateModel): ProductProperty {
    return state?.productProperties;
  }

  @Selector()
  static categories(state: ProductStateModel): Category[] {
    return state?.categories;
  }

  @Selector()
  static smallestProductWidth(state: ProductStateModel): number {
    let smallestWidth: number = state?.products[0].width;
    state?.products.forEach(product => {
      if (product.width < smallestWidth) smallestWidth = product.width
    })
    return smallestWidth;
  }

  @Selector()
  static widestProductWidth(state: ProductStateModel): number {
    let widestWidth: number = state?.products[0].width;
    state?.products.forEach(product => {
      if (product.width > widestWidth) widestWidth = product.width
    })
    return widestWidth;
  }

  @Action(FetchAllProducts)
  fetchAllProducts(ctx: StateContext<ProductStateModel>) {
    // Use products.json as data for the products
    if (!environment.useWooCommerce) return this.productService.fetchAllProducts().pipe(
      tap((products: Product[]) => {
        ctx.setState(
          patch<ProductStateModel>({
            products: products
          })
        )
      })
    )

    // Use WooCommerce API as data for the products
    return this.productService.fetchAllProductsWithColors().pipe(
      tap((products: Product[]) => {
        ctx.setState(
          patch<ProductStateModel>({
            products: products
          })
        )
      })
    )
  }

  @Action(FetchAllCategories)
  fetchAllCategories(ctx: StateContext<ProductStateModel>) {
    const all: Category = {
      id: -1,
      name: 'Alle kasten',
    }

    this.store.dispatch(new UpdateSelectedCategory(all))
    // Use products.json as data for the products
    if (!environment.useWooCommerce) return this.productService.fetchAllCategories().pipe(
      tap((categories: Category[]) => {
        categories.unshift(all)
        ctx.setState(
          patch<ProductStateModel>({
            categories: categories
          })
        )
      })
    )

    return this.productService.fetchAllCategoriesFromWooCommerce().pipe(
      tap((categories: Category[]) => {
        categories.unshift(all)
        ctx.setState(
          patch<ProductStateModel>({
            categories: categories
          })
        )
      })
    )
  }

  @Action(FetchAllColorProperties)
  fetchAllColorProperties(ctx: StateContext<ProductStateModel>) {
    return this.productService.fetchAllColorProperties().pipe(
      tap((productProperties: ProductProperty) => {
        ctx.setState(
          patch<ProductStateModel>({
            productProperties: productProperties
          })
        )
      })
    )
  }

  @Action(SetColorProperties)
  setColorProperties(ctx: StateContext<ProductStateModel>, action: SetColorProperties) {
    ctx.setState(
      patch<ProductStateModel>({
        productProperties: action.productProperties
      })
    )
  }

  @Action(UpdateSelectedVariation)
  updateSelectedVariant(ctx: StateContext<ProductStateModel>, action: UpdateSelectedVariation) {
    const newSelectedVariation: ProductVariation | undefined = ctx.getState().products.find(product => product.id === action.productId)?.variations?.find(variation => variation.id === action.variationId);
    if (!newSelectedVariation) return;
    ctx.setState(
      patch<ProductStateModel>({
        products: updateItem<Product>(
          product => product.id === action.productId,
          patch<Product>({
            selectedVariation: newSelectedVariation
          })
        )
      })
    )
  }

  @Action(GetNumberOfProductsForEachCategory)
  getNumberOfProductsForEachCategory(ctx: StateContext<ProductStateModel>, action: GetNumberOfProductsForEachCategory) {
    if (!ctx.getState().categories || ctx.getState().categories.length < 1) return;

    ctx.getState().categories.forEach(c => {
      const numberOfProductsForCategory: number = filterProductsForEachCategory(action.products, action.search, action.color, c, action.stack)?.length;
      ctx.setState(
        patch<ProductStateModel>({
          categories: updateItem<Category>(
            category => category.id === c.id,
            patch<Category>({
              numberOfProducts: numberOfProductsForCategory,
            })
          )
        })
      )
    })
  }
}
