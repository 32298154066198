<div class="modal__header">
  <div class="modal__header-text">
    <h2 class="modal__title">{{ 'configurator.edit-room-modal.title' | translate }}</h2>
  </div>
  <app-modal-close-button (onClose)="closeModal()"></app-modal-close-button>
</div>
<div class="modal__content">
  <div>
    <p class="subtitle mb-2">{{ 'configurator.edit-room-modal.room' | translate }}</p>
    <div class="text--grey flex items-center">
      <span class="material-symbols-outlined mr-2">info</span>
      <p>{{ 'configurator.edit-room-modal.room-info' | translate }}</p>
    </div>
    <div class="flex my-8">
      <div class="w-full">
        <label class="label mb-4" for="width">
          {{ 'configurator.edit-room-modal.height' | translate }}
          <span class="mt-1">
          <input (change)="updateRoomDimensions()" [(ngModel)]="roomHeight" id="width" type="number"> cm
        </span>
        </label>
        <label class="label" for="height">
          {{ 'configurator.edit-room-modal.width' | translate }}
          <span class="mt-1">
          <input (change)="updateRoomDimensions()" [(ngModel)]="roomWidth" id="height" type="number"> cm
        </span>
        </label>
      </div>
      <div class="room-placeholder__container">
        <div class="room-placeholder__width">
          <div class="room-placeholder"></div>
          <div class="configurator__measurement-holder configurator__measurement-holder--horizontal w-full">
            <div class="line w-full mt-4"></div>
            <p class="!mb-0 !mt-2">{{ roomWidth }}cm</p>
          </div>
        </div>
        <div
          class="configurator__measurement-holder mr-2 configurator__measurement-holder--vertical !items-start !pb-0 h-[7rem] min-w-[7rem]">
          <div class="line__container h-[7rem] !px-4">
            <div class="line h-[7rem]"></div>
            <p class="!ml-2 !mr-0">{{ roomHeight }}cm</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="roomProperties$ | async; else loading">
    <div>
      <p class="subtitle mb-2">{{ 'configurator.edit-room-modal.floor' | translate }}</p>
      <div class="option__container">
        <label
          *ngFor="let floorProperty of roomProperties.floorProperties"
          [for]="floorProperty.id"
          class="option__label">
          <input
            (change)="updateFloorProperty()"
            [(ngModel)]="selectedFloorProperty"
            [id]="floorProperty.id"
            [value]="floorProperty"
            name="floorProperty"
            type="radio"
          >
          <span class="label__container">
        <div class="option__title__container">
          <span class="option__title">{{ floorProperty.name }}</span>
          <span class="material-symbols-outlined option--selected">
            check_circle
          </span>
          <span class="material-symbols-outlined option--not-selected">
            radio_button_unchecked
          </span>
        </div>
        <img *ngIf="floorProperty.image !== 'default'" [alt]="floorProperty.name" [src]="floorProperty.image"
             class="label__img">
      </span>
        </label>
      </div>
    </div>
    <div>
      <p class="subtitle mb-2">{{ 'configurator.edit-room-modal.wall' | translate }}</p>
      <div class="option__container">
        <label
          *ngFor="let wallProperty of roomProperties.wallProperties"
          [for]="wallProperty.id"
          class="option__label">
          <input
            (change)="updateWallProperty()"
            [(ngModel)]="selectedWallProperty"
            [id]="wallProperty.id"
            [value]="wallProperty"
            name="wallProperty"
            type="radio"
          >
          <span class="label__container">
        <div class="option__title__container">
          <span class="option__title">{{ wallProperty.name }}</span>
          <span class="material-symbols-outlined option--selected">
            check_circle
          </span>
          <span class="material-symbols-outlined option--not-selected">
            radio_button_unchecked
          </span>
        </div>
        <img *ngIf="wallProperty.image !== 'default'" [alt]="wallProperty.name" [src]="wallProperty.image"
             class="label__img">
      </span>
        </label>
      </div>
    </div>

  </ng-container>
  <ng-template #loading>
    <loading-icon></loading-icon>
  </ng-template>
</div>
