<div class="card card__product">
  <div class="card__header">
    <div class="card__header-text">

      <div class="flex items-center gap-2">
        <a (click)="$event.stopPropagation()" [href]="product.productUrl" class="flex" target="_blank">
          <span class="icon--small material-symbols-outlined text--grey">
            info
          </span>
        </a>

        <h3 class="card__title">{{product.title}}</h3>
      </div>
      <p
        class="card__subtitle">{{product.width}}&times;{{productHeight}}
        cm</p>
    </div>
  </div>

  <div class="card__content">
    <div class="card__product-img">
      <img
        [alt]="product.title"
        [src]="product.imgUrl"
        onerror="this.src='assets/images/fallback.png'"
      >
    </div>

    <p class="price">
      <ng-container *ngIf="product.variations && product.variations.length > 0">{{ 'products.product-card.from' | translate }}&nbsp;</ng-container>
      <span class="euro">€</span>
      <span class="price">
        {{(product.selectedVariation ? product.selectedVariation.price : product.price) | number:'.2':'nl'}}
      </span>
    </p>
  </div>

</div>
