export default class Color {
  static readonly BLACK = new Color('zwart', 'BLACK');
  static readonly WHITE = new Color('wit', 'WHITE');

  private constructor(public readonly title: string, public readonly value: string) {
  }

  static findColor(value: string): Color {
    value = value.toUpperCase();
    switch (value) {
      case this.BLACK.value:
        return this.BLACK;
      case this.WHITE.value:
        return this.WHITE;
      default:
        throw new Error('Color not found...')
    }
  }

  static findColorFromWooCommerce(value: string): Color {
    if (value.toUpperCase().includes(this.BLACK.title.toUpperCase())) return Color.BLACK
    return Color.WHITE
  }
}
