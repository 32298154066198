import {NgModule} from "@angular/core";
import {ModalComponent} from "./modal/modal.component";
import {NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import {ModalCloseButtonComponent} from './components/modal-close-button/modal-close-button.component';
import {ProductTitlePipe} from './pipes/product-title.pipe';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import {FormsModule} from "@angular/forms";
import {EmptyResultsComponent} from './components/empty-results/empty-results.component';
import {OrderVariationsByHeightPipe} from './pipes/order-variations-by-height.pipe';
import {FilterProductsPipe} from './pipes/filter-products.pipe';
import {PopUpMessageComponent} from './pop-up/components/pop-up-message/pop-up-message.component';
import {PopUpHolderComponent} from './pop-up/components/pop-up-holder/pop-up-holder.component';
import {LoadingIconComponent} from './components/loading-icon/loading-icon.component';
import {FilterAccessoriesPipe} from './pipes/filter-accessories.pipe';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {TooltipComponent} from './tooltip/tooltip.component';
import {TooltipDirective} from './tooltip/tooltip.directive';
import {AutofocusDirective} from './directives/autofocus.directive';
import { FilterCategoriesPipe } from './pipes/filter-categories.pipe';
import { TranslationButtonComponent } from './components/translation-button/translation-button.component';
import {TranslateModule} from "@ngx-translate/core";
import { UspBannerComponent } from './components/usp-banner/usp-banner.component';
import { UspModalComponent } from './components/usp-modal/usp-modal.component';

@NgModule({
  declarations: [
    ModalComponent,
    ModalCloseButtonComponent,
    ProductTitlePipe,
    SearchBarComponent,
    EmptyResultsComponent,
    OrderVariationsByHeightPipe,
    FilterProductsPipe,
    PopUpMessageComponent,
    PopUpHolderComponent,
    LoadingIconComponent,
    FilterAccessoriesPipe,
    ConfirmationModalComponent,
    TooltipComponent,
    TooltipDirective,
    AutofocusDirective,
    FilterCategoriesPipe,
    TranslationButtonComponent,
    UspBannerComponent,
    UspModalComponent,
  ],
  imports: [
    NgClass,
    FormsModule,
    NgForOf,
    NgStyle,
    NgIf,
    TranslateModule
  ],
    exports: [
        ModalComponent,
        ModalCloseButtonComponent,
        SearchBarComponent,
        ProductTitlePipe,
        EmptyResultsComponent,
        OrderVariationsByHeightPipe,
        FilterProductsPipe,
        PopUpHolderComponent,
        LoadingIconComponent,
        FilterAccessoriesPipe,
        ConfirmationModalComponent,
        TooltipDirective,
        FilterCategoriesPipe,
        TranslationButtonComponent,
        UspBannerComponent,
        UspModalComponent
    ]
})
export class SharedModule {
}
