import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {forkJoin, map, Observable} from "rxjs";
import {Product} from "./models/product";
import Color from "./models/colors";
import {createProductImgUrl, createVariationImgUrl} from "../shared/helpers/product.helpers";
import {ProductVariation} from "./models/product-variation";
import {Accessory} from "./models/accessory";
import {Category} from "./models/category";
import {ProductProperty} from "../configurator/models/product-property";
import {v4 as uuid} from "uuid";
import {Store} from "@ngxs/store";
import {SetColorProperties} from "./product.actions";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private readonly productBaseUrl: string;
  private readonly categoriesBaseUrl: string;
  private readonly wooCommerceUrl: string;
  private readonly wooCommerceHeaders: HttpHeaders;
  private readonly wooCommerceParams: HttpParams;
  private readonly wooCommerceParamsForVariations: HttpParams;

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) {
    this.productBaseUrl = `${environment.apiUrl}/assets/products.json`
    this.categoriesBaseUrl = `${environment.apiUrl}/assets/categories.json`
    this.wooCommerceUrl = `${environment.wooCommerceUrl}/configurator`

    this.wooCommerceParams = new HttpParams()
      .set('configurator', 1)
      .set('per_page', 100)
      .set('_fields', 'id,attributes,name,price,permalink,images')

    this.wooCommerceParamsForVariations = new HttpParams()
      .set('_fields', 'id,attributes,name,price,permalink,image')
  }

  fetchAllProducts(): Observable<Product[]> {
    return this.httpClient.get<any[]>(this.productBaseUrl)
      .pipe(
        map((data) => {
          const returnProducts: Product[] = [];
          data.forEach((product) => {
            const newProduct = this.mapProductFromWooCommerce(product)
            if (newProduct) returnProducts.push(newProduct);
          });

          return returnProducts;
        }))
  }

  fetchAllProductsFromWooCommerce(): Observable<Product[]> {
    return this.httpClient.get<any[]>(
      this.wooCommerceUrl)
      .pipe(
        map((data) => {
          const returnProducts: Product[] = [];
          data.forEach((product) => {
            const newProduct = this.mapProductFromWooCommerce(product)
            if (newProduct) returnProducts.push(newProduct);
          });

          return returnProducts;
        }))
  }

  fetchAllProductsWithColors(): Observable<Product[]> {
    return forkJoin({
      products: this.fetchAllProductsFromWooCommerce(),
      colorProperties: this.fetchAllColorProperties()
    }).pipe(
      map(({products, colorProperties}) => {
        this.store.dispatch(new SetColorProperties(colorProperties));
        return products
          .map((product, index) => {
            product.colorProperty = colorProperties.colors.find((colorProperty) => colorProperty.name.toLowerCase().includes(product.colorString.toLowerCase()))
            product.variations = product.variations
              .filter(variation => {
                return variation.colorString !== undefined
              })
              .map(variation => {
                variation.colorProperty = colorProperties.colors.find(colorProperty =>
                  colorProperty.name.toLowerCase().includes(variation.colorString?.replace('-', ' ').toLowerCase())
                );
                return variation;
              });
            return product
          }).filter(product => {
            return product.variations.length > 0
          })
      }))
  }

  fetchAllCategories(): Observable<Category[]> {
    return this.httpClient.get<any>(
      this.categoriesBaseUrl
    ).pipe(
      map((data) => {
        const categories: Category[] = [];
        data.children.forEach((c: any) => {
          categories.push({
            id: c.id,
            name: c.name,
          })
        })
        return categories;
      })
    )
  }

  fetchAllCategoriesFromWooCommerce(): Observable<Category[]> {
    return this.httpClient.get<any>(
      `${this.wooCommerceUrl}/categories`
    ).pipe(
      map((data) => {
        const categories: Category[] = [];
        data.children.forEach((c: any) => {
          categories.push({
            id: c.id,
            name: c.name,
          })
        })
        return categories;
      })
    )
  }

  fetchAllColorProperties(): Observable<ProductProperty> {
    return this.httpClient.get<any>(
      `${this.wooCommerceUrl}/product-properties`
    ).pipe(
      map((data) => {
        const productProperties: ProductProperty = {
          colors: Object.values(data.colors)
        };
        productProperties.colors.forEach((color) => color.id = uuid())
        return productProperties;
      })
    )
  }

  private mapProduct(product: Product): Product {
    if (product.variations && product.variations.length > 0) product.selectedVariation = product.variations.sort((a, b) => a.height - b.height)[0];

    if (product.colorText) product.color = Color.findColor(product.colorText);
    product.imgUrl = `${createProductImgUrl(product)}`;
    const productName = product.title.split(' ').reduce((name, currentValue) => `${name}-${currentValue}`).toLowerCase()
    product.productUrl = `https://wasombouw.nl/product/${productName}-${product.color.title.toLowerCase()}/`;

    if (product.accessories && product.accessories.length > 0) {
      product.accessories.forEach(accessory => {
        accessory.color = Color.findColor(accessory.colorText);
        accessory.numberInConfiguration = 0;
      })
    }

    product.variations?.forEach(variation => {
      variation.imgUrl = createVariationImgUrl(variation, product)
    })

    return product;
  }

  private mapProductFromWooCommerce(wooCommerceProduct: any): Product | undefined {
    if (
      !wooCommerceProduct.attributes.find((attribute: any) => attribute.code === "pa_color") ||
      !wooCommerceProduct.attributes.find((attribute: any) => attribute.code === "pa_length") ||
      !wooCommerceProduct.attributes.find((attribute: any) => attribute.code === "pa_height")
    ) {
      console.log(`Product values are not set: ${wooCommerceProduct.name} - ${wooCommerceProduct.id}`)
      return;
    }

    const product: Product = {
      id: wooCommerceProduct.id,
      title: wooCommerceProduct.name,
      price: parseFloat(wooCommerceProduct.price),
      productUrl: wooCommerceProduct.permalink,
      color: Color.findColorFromWooCommerce(wooCommerceProduct.attributes.find((attribute: any) => attribute.code === "pa_color").values[0]),
      colorString: wooCommerceProduct.attributes.find((attribute: any) => attribute.code === "pa_color").values[0],
      width: parseInt(wooCommerceProduct.attributes.find((attribute: any) => attribute.code === "pa_length").values[0]),
      height: parseInt(wooCommerceProduct.attributes.find((attribute: any) => attribute.code === "pa_height").values[0]),
      variations: [],
      imgUrl: wooCommerceProduct.images.defaultUrl,
      alternativeImgUrl: wooCommerceProduct.images.alternativeUrl ? wooCommerceProduct.images.alternativeUrl : null,
      isHangingCloset: wooCommerceProduct.isHangingCabinet == 1,
      categories: wooCommerceProduct.category,
      alternativeColor: wooCommerceProduct.alternativeColor,
    };

    let variations: ProductVariation[] = [];
    wooCommerceProduct.variations?.forEach((variation: any) => {
      const newVariation: ProductVariation = {
        id: variation.variation_id,
        height: parseInt(variation.attributes.attribute_pa_height ?? 0),
        colorString: variation.attributes.attribute_pa_color,
        price: variation.display_price,
        imgUrl: wooCommerceProduct.images.variations.find((v: any) => v.id == variation.variation_id)?.url,
        alternativeImgUrl: wooCommerceProduct.images.variations.find((v: any) => v.id == variation.variation_id)?.alternative ? wooCommerceProduct.images.variations.find((v: any) => v.id == variation.variation_id)?.alternative : null,
        productUrl: wooCommerceProduct.permalink
      }
      variations.push(newVariation)
    });

    let accessories: Accessory[] = [];
    wooCommerceProduct.accessories?.forEach((accessory: any) => {
      const color = accessory.attributes?.find((attribute: any) => attribute.code === 'pa_color')

      const newAccessory: Accessory = {
        id: accessory.id,
        color: color ? Color.findColorFromWooCommerce(color.values[0]) : null,
        price: accessory.price,
        imgUrl: accessory.images[0]?.src,
        title: accessory.name,
        numberInConfiguration: 0,
      }
      //newAccessory.imgUrl = createAccessoryImgUrl(newAccessory)
      accessories.push(newAccessory);
    })

    product.variations = variations;
    product.accessories = accessories;
    return product;
  }

  private mapVariationFromWooCommerce(wooCommerceVariation: any): ProductVariation | undefined {
    if (
      !wooCommerceVariation.attributes.find((attribute: any) => attribute.name === "Hoogte")
    ) return;

    return {
      id: wooCommerceVariation.id,
      colorString: wooCommerceVariation.attributes.attribute_pa_color,
      height: wooCommerceVariation.attributes.find((attribute: any) => attribute.name === "Hoogte").option,
      price: wooCommerceVariation.price,
      imgUrl: wooCommerceVariation.image.src,
      productUrl: wooCommerceVariation.permalink
    }
  }
}
