import { Component } from '@angular/core';
import {Store} from "@ngxs/store";
import {ModalService} from "../../modal/modal.service";

@Component({
  selector: 'app-usp-banner',
  templateUrl: './usp-banner.component.html',
  styleUrls: ['./usp-banner.component.scss']
})
export class UspBannerComponent {

  constructor(
    private modalService: ModalService,
  ) {
  }

  onOpenUSPModal() {
    this.modalService.open('usp-modal');
  }
}
