<div class="category__list">
  <ng-container *ngFor="let category of categories | filterCategories">
    <div class="category__container" [ngClass]="{'category__container--selected': selectedCategory?.id === category.id}" (click)="updateSelectedCategory(category)">
      <p class="category__title">
        {{ category.name }}
      </p>
      <span class="category__number">
        {{category.numberOfProducts}}
      </span>
    </div>
  </ng-container>
</div>
