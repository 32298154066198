<div class="usp__container">
  <div class="usp__information">
    <div class="usp__information__group">
      <div class="usp__element">
      <span class="material-symbols-outlined">
        forest
      </span>
        <p>{{ "shared.usp.usp-1" | translate }}</p>
      </div>
      <div class="usp__element">
      <span class="material-symbols-outlined">
        weight
      </span>
        <p>{{ "shared.usp.usp-2" | translate }}</p>
      </div>
    </div>
    <div class="usp__information__group">

      <div class="usp__element">
      <span class="material-symbols-outlined">
        schedule
      </span>
        <p>{{ "shared.usp.usp-3" | translate }}</p>
      </div>
      <div class="usp__element">
      <span class="material-symbols-outlined">
        construction
      </span>
        <p>{{ "shared.usp.usp-4" | translate }}</p>
      </div>
    </div>
  </div>
</div>

<modal id="usp-modal" modalClass="modal--giant">
  <app-usp-modal
    modalId="usp-modal">
  </app-usp-modal>
</modal>
