import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Product} from "../../models/product";
import {ModalService} from "../../../shared/modal/modal.service";
import {Store} from "@ngxs/store";
import {ModalIdRequiredError} from "../../../shared/custom-errors/modal-id-required.error";
import {Accessory} from "../../models/accessory";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UpdateNumberOfAccessoriesInProduct} from "../../../configurator/configurator.actions";
import {SearchBarComponent} from "../../../shared/components/search-bar/search-bar.component";

@Component({
  selector: 'app-product-info-modal',
  templateUrl: './product-info-modal.component.html',
  styleUrls: ['./product-info-modal.component.scss']
})
export class ProductInfoModalComponent implements OnInit {
  @Input() modalId: string;
  @Input() product: Product;
  @Input() modalClass: string;

  @ViewChild('searchBarComponent') searchBar: SearchBarComponent;

  form: FormGroup;
  searchKeyword: string = '';


  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    if (!this.modalId) throw new ModalIdRequiredError();
    this.createForm();
  }

  closeModal() {
    this.modalService.close(this.modalId);
  }

  saveAccessories() {
    const accessories: Accessory[] = JSON.parse(JSON.stringify(this.product.accessories));
    accessories.forEach(accessory => {
      accessory.numberInConfiguration = this.form.controls[`${accessory.id}-number`].value
    })
    this.store.dispatch(new UpdateNumberOfAccessoriesInProduct(this.product.configurationId, accessories))
  }

  focusOnSearchbar() {
    this.searchBar?.focus();
  }

  private createForm() {
    let controls: any = {};
    this.product.accessories?.forEach(accessory => {
      controls[`${accessory.id}-number`] = new FormControl(
        accessory.numberInConfiguration,
        [Validators.min(0)]
      );
    });
    this.form = this.fb.group(controls)
  }
}
