<div class="usp__modal">
  <div class="modal__header">
    <div class="modal__header-text">
      <h2 class="modal__title">{{ "shared.usp.modal-title" | translate }}</h2>
      <p class="modal__subtitle">{{ "shared.usp.modal-subtitle" | translate }}</p>
    </div>
  </div>

  <div class="usp__gallery">
    <div class="row">
      <div class="col">
        <img
          src="assets/images/usp/4S4uD1O10BVdIvuPw3sugmGVS04MhlGMXsTrm6GJ8qyHWPbLvlAjDC9hyA5KyKeCgAKHxG0vPU7-20241111-cz-wasombouw-31-2.jpg">
      </div>
      <div class="col">
        <img
          src="assets/images/usp/cWFyb5y07plaSdFtY5uFGc87gyTWcB4z268Byv46x51mqiWbtRKTeBKqXq5Szui19i9bkIku8tS-20241111-cz-wasombouw-1-1.jpg">
      </div>
      <div class="col">
        <img
          src="assets/images/usp/HNqepptPSSbzHSN5RSdayKpkQscVNwZPcrEUvGOUKVDbeUkAveua4l3o7GlqSBgG7EM5mCkDwKl-20241104-cz-wasombouw-7-1.jpg">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <img src="assets/images/usp/QAdjcxZJ0lBcqI49BSn7NZcvJGXlHBI8Y3KjaYu6WNvmLUqyP6a1Ti7MNXu6eUF4VJKFrEp8ue8-20241104-cz-wasombouw-3-2.jpg">
      </div>
      <div class="col">
        <img src="assets/images/usp/cZo85po6vWMahDmlzMkFb7XLOMFNjRYp0nLzPpXIH4n8pcKDgUccIyxg3TJwS9ki0xW4RCukSdq-20240712-cz-wasombouw-2.jpg">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <img
          src="assets/images/usp/isxgjpxV1uT3HAp8c029D5i0zFu8AoNiUUgydoCUavepbdn6ofjbV2uYCmkLEsRuy1x4q9GyJTb-20240712-cz-wasombouw-31-1.jpg">
      </div>
      <div class="col">
        <img
          src="assets/images/usp/U9anlOwcvUFbqZysQTyw5h6YRWBQGPedqPjXR0UNnDQgq7u4A57xTneWINC3jG27AiHDX0nFEEF-20240731-cz-wasombouw-4-2.jpg">
      </div>
      <div class="col">
        <img
          src="assets/images/usp/B892eqoMMwRPlQpFgQQa6vfsqqCFVDNORoYnizPLqxcQyWw42th0p6kCgafjUWKxyTuZHPIcdKE-20241111-cz-wasombouw-13-1.jpg">
      </div>
    </div>
  </div>

</div>
