<div [attr.aria-expanded]="configurationExpanded" class="configuration">
  <div class="configuration__colors">
    <app-color-switch-buttons [products]="products"></app-color-switch-buttons>
  </div>

  <div (click)="configurationExpanded = !configurationExpanded"
       [ngClass]="{'configuration__header--no-products': products.length === 0}" class="configuration__header">
    <div class="configuration__header__title">
      <button class="flex items-center">
        <span class="material-symbols-outlined desktop">
          <ng-container *ngIf="!configurationExpanded">expand_less</ng-container>
          <ng-container *ngIf="configurationExpanded">expand_more</ng-container>
        </span>
        <span class="material-symbols-outlined mobile">
          <ng-container *ngIf="!configurationExpanded">expand_more</ng-container>
          <ng-container *ngIf="configurationExpanded">expand_less</ng-container>
        </span>
      </button>
      <h2 class="configuration__title">
        {{ 'configurator.product-list.title' | translate }}
        <span *ngIf="products.length > 0" class="text--grey text--normal">
          ({{ products.length }})
        </span>
      </h2>
    </div>
    <div *ngIf="products.length > 0" class="configuration__header__info">
      <p class="configuration__total--price price">
        <span class="euro">€</span>
        <span>{{ totalPrice | number:'.2':'nl' }}</span>
      </p>
    </div>

  </div>



  <ng-container *ngIf="products.length > 0; else emptyMessage">
    <div *ngFor="let product of products" class="configuration__product">
      <ng-container *ngIf="product.numberInConfiguration">

        <div class="configuration__product__title-container">
          <div class="flex items-center gap-2">
            <a (click)="$event.stopPropagation()" [href]="product.productUrl" class="flex" target="_blank">
          <span class="icon--small text--grey material-symbols-outlined">
            info
          </span>
            </a>
            <p class="configuration__product__title">
              {{ product.title }}
              <span *ngIf="product.selectedVariation.height"
                    class="text--grey text--normal">| {{ product.selectedVariation.height }}cm</span>
            </p>
          </div>
          <p class="configuration__product__number">
            {{ product.numberInConfiguration }}&times;
            €{{ (product.selectedVariation ? product.selectedVariation.price : product.price) | number:'.2':'nl' }}
          </p>
        </div>
        <div class="configuration__product__dimensions-container">
          <p class="configuration__product__dimensions">
            {{ product.width }}&times;{{ product.selectedVariation ? product.selectedVariation.height : product.height }}
            cm
            - {{ product.selectedVariation.colorProperty.name }}
          </p>
        </div>
        <div class="configuration__product__accessories-container">
          <ng-container *ngFor="let accessory of product.accessories">
            <div *ngIf="accessory.numberInConfiguration > 0" class="configuration__product__accessories">
              <p class="configuration__product__accessories__text">
              <span class="material-symbols-outlined text--grey">
                subdirectory_arrow_right
              </span>
                {{ accessory.title }}
              </p>
              <p class="whitespace-nowrap">{{ accessory.numberInConfiguration }}&times;
                €{{ accessory.price | number:'.2':'nl' }}</p>
            </div>

          </ng-container>
        </div>
        <div class="configuration__product__price-container">
          <p *ngIf="product.numberInConfiguration"
             class="configuration__product__price--total price"
          >
            <span class="euro">€</span>
            <span>{{ calculateTotalPriceForProduct(product) | number:'.2':'nl' }}</span>
          </p>
        </div>
      </ng-container>


    </div>
    <div class="configuration__actions">
      <div class="configuration__total-container">
        <p class="configuration__total--title">
          {{ 'configurator.product-list.total' | translate }}
        </p>
        <p class="configuration__total--price price">
          <span class="euro">€</span>
          <span class="price">{{ totalPrice | number:'.2':'nl' }}</span>
        </p>
      </div>
      <div class="flex gap-2">
        <button (click)="$event.stopPropagation(); addToCart()"
                class="button button--primary button--icon-text text--bold w-full">
          <span class="material-symbols-outlined">shopping_cart</span>
          {{ 'configurator.product-list.shopping-cart' | translate }}
        </button>
        <button
          (click)="$event.stopPropagation(); openConfigurationModal()"
          [position]="TooltipPosition.LEFT"
          [tooltip]="'configurator.product-list.reset' | translate"
          [zIndex]="1000"
          class="button button--outline button--icon text--grey"
        >
          <span class="material-symbols-outlined">
            restart_alt
          </span>
        </button>
      </div>
    </div>

  </ng-container>
  <ng-template #emptyMessage>
    <div class="configuration__product">
      {{ 'configurator.product-list.empty' | translate }}
    </div>
  </ng-template>
</div>

<modal [id]="confirmationModalId">
  <app-confirmation-modal
    (confirm)="resetConfiguration()"
    [modalId]="confirmationModalId"
    [text]="'configurator.product-list.confirmation-text' | translate"
    [title]="'configurator.product-list.confirmation-title' | translate"
  >
  </app-confirmation-modal>
</modal>
