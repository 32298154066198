import {Component, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SearchBarComponent
    }
  ]
})
export class SearchBarComponent implements ControlValueAccessor {
  value: string;
  @Input() placeholder: string = '';

  @ViewChild('inputElement') inputElement: any;

  get searchKeyword(): string {
    return this.value;
  }

  set searchKeyword(searchKeyword: string) {
    this.value = searchKeyword;
    this.onChange(searchKeyword)
    this.onTouch(searchKeyword)
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(searchKeyword: string): void {
    this.searchKeyword = searchKeyword
  }

  focus() {
    this.inputElement.nativeElement.focus()
  }
}
