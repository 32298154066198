import {Pipe, PipeTransform} from '@angular/core';
import {Product} from "../../products/models/product";
import {ProductStack} from "../../configurator/models/product-stack";
import Color from "../../products/models/colors";
import {Category} from "../../products/models/category";
import {Store} from "@ngxs/store";
import {GetNumberOfProductsForEachCategory} from "../../products/product.actions";
import {filterProductsForEachCategory} from "../helpers/product.helpers";

@Pipe({
  name: 'filterProducts'
})
export class FilterProductsPipe implements PipeTransform {
  constructor(private store: Store) {
  }

  transform(products: Product[], search: string, color: Color, selectedCategory: Category, stack?: ProductStack): Product[] {
    this.store.dispatch(new GetNumberOfProductsForEachCategory(products, search, color, selectedCategory, stack));
    return filterProductsForEachCategory(products, search, color, selectedCategory, stack);
  }
}
