import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalIdRequiredError} from "../../../shared/custom-errors/modal-id-required.error";
import {ModalService} from "../../../shared/modal/modal.service";
import {Select, Store} from "@ngxs/store";
import {Product} from "../../models/product";
import {UpdateHangingHeightOfProduct} from "../../../configurator/configurator.actions";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {min, Observable, Subscription} from "rxjs";
import {ConfiguratorState} from "../../../configurator/configurator.state";
import {Configuration, getTotalHeightBelowProductInConfiguration} from "../../../configurator/models/configuration";

@Component({
  selector: 'app-product-settings-modal',
  templateUrl: './product-settings-modal.component.html',
  styleUrls: ['./product-settings-modal.component.scss']
})
export class ProductSettingsModalComponent implements OnInit, OnDestroy {
  @Select(ConfiguratorState.totalHeight) totalHeight$: Observable<number>;
  @Select(ConfiguratorState.configuration) configuration$: Observable<Configuration>;
  totalHeight: number;
  configuration: Configuration;

  @Input() modalId: string;
  @Input() product: Product;
  form: FormGroup;
  minHeight: number;
  protected readonly min = min;
  private subscriptions = new Subscription();
  errorParams = {value: 0};

  constructor(
    private modalService: ModalService,
    private store: Store,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    if (!this.modalId) throw new ModalIdRequiredError();
    this.createForm();
    this.fetchData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  closeModal() {
    this.modalService.close(this.modalId);
  }

  updateHangingHeight() {
    if (this.form.invalid) return;

    this.store.dispatch(new UpdateHangingHeightOfProduct(this.product.configurationId, this.form.value.height));
  }

  setHeightToTotalHeight() {
    this.form.controls['height'].setValue(Math.max(this.totalHeight, this.minHeight));
    this.updateHangingHeight();
  }

  private createForm() {
    this.minHeight = getTotalHeightBelowProductInConfiguration(this.configuration, this.product.configurationId) + this.product.height
    this.errorParams.value = this.minHeight;
    this.form = this.fb.group({
      height: [this.product.hangingHeight, Validators.min(this.minHeight)]
    })
  }

  private fetchData() {
    this.subscriptions.add(
      this.totalHeight$.subscribe((totalHeight) => {
        this.totalHeight = totalHeight;
      })
    )
    this.subscriptions.add(
      this.configuration$.subscribe(configuration => {
        this.configuration = configuration;
        this.createForm();
      })
    )
  }
}
