import {Pipe, PipeTransform} from '@angular/core';
import {Accessory} from "../../products/models/accessory";

@Pipe({
  name: 'filterAccessories'
})
export class FilterAccessoriesPipe implements PipeTransform {

  transform(accessories: Accessory[], search: string): Accessory[] {
    return accessories.filter(accessory => accessory.title.toLowerCase().includes(search.toLowerCase()))
  }
}
