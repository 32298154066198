<form [formGroup]="form" class="configurator__buttons--colors">
  <ng-container *ngIf="productProperties?.colors?.length > 0">
    <label
      *ngFor="let colorProperty of productProperties?.colors"
      [tooltip]="('configurator.configurator.change-color' | translate) + ' ' +  colorProperty.name.toLowerCase()" [zIndex]="1000"
      class="configurator__color-chooser configurator__color-chooser--black"
      [for]="'configurator-color-' + colorProperty.id">
      <input
        (change)="updateColor(colorProperty.id)"
        formControlName="selectedColor"
        [value]="colorProperty"
        [id]="'configurator-color-' + colorProperty.id"
        type="radio">
      <div class="color__container">
        <div
          [style]="{'background': colorProperty.image ? 'url('+colorProperty.image+')' : colorProperty.hex}"></div>
        <div class="color__check">
            <span class="material-symbols-outlined">
              check
            </span>
        </div>
      </div>
    </label>

  </ng-container>
  <input formControlName="selectedColor" [value]="undefined" class="hidden" id="undefined" type="radio">
</form>
