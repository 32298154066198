import {Product} from "../../products/models/product";
import {
  checkIfAllProductsHaveSameColorInProductStack,
  getHeightFromStack,
  getLevelFromStack,
  getProductWithConfigurationIdFormStack,
  getStackFromStack,
  getTotalHeightBelowProductInProductStack,
  getWidthForStack,
  ProductStack
} from "./product-stack";
import {CalculateHeightBelowProduct, Configuration, getTotalHeightBelowProductInConfiguration} from "./configuration";
import Color from "../../products/models/colors";
import {ColorProperty} from "../../products/models/color-property";

export class ProductLevel {
  id: string;
  product?: Product;
  productStacks?: (ProductStack | undefined)[]

  constructor(id: string, product?: Product, productStacks?: (ProductStack | undefined)[]) {
    this.id = id;
    this.product = product;
    this.productStacks = productStacks;
  }
}

// Recursive can't be placed in the class because after JSON.parse(JSON.stringify()) the methods aren't copied to the new object.

export function getStackFromLevel(productLevel: ProductLevel, stackId: string): ProductStack | undefined {
  if (!productLevel.productStacks || productLevel.productStacks?.length < 1) return;
  for (let i = 0; i < productLevel.productStacks.length; i++) {
    const productStack = productLevel.productStacks[i]
    if (productStack) {
      const stack = getStackFromStack(productStack, stackId);
      if (stack) return stack;
    }
  }
  return;
}

export function getLevelFromLevel(productLevel: ProductLevel, levelId: string): ProductLevel | undefined {
  if (productLevel.id === levelId) return productLevel;
  if (!productLevel.productStacks || productLevel.productStacks?.length < 1) return;
  for (let i = 0; i < productLevel.productStacks.length; i++) {
    const productStack = productLevel.productStacks[i]
    if (!productStack) return;
    const level = getLevelFromStack(productStack, levelId);
    if (level) return level;
  }
  return;
}

export function getProductWithConfigurationIdFormLevel(productLevel: ProductLevel, configurationId: string): Product | undefined {
  if (productLevel.product?.configurationId === configurationId) return productLevel.product;
  if (!productLevel.productStacks || productLevel.productStacks.length < 1) return;
  for (let i = 0; i < productLevel.productStacks.length; i++) {
    const productStack = productLevel.productStacks[i]
    if (productStack) {
      const product = getProductWithConfigurationIdFormStack(productStack, configurationId);
      if (product) return product;
    }
  }
  return;
}

export function getHeightFromLevel(productLevel: ProductLevel, configuration: Configuration): any {
  let heightObj = {
    height: 0,
    heightForHangingProducts: 0,
  }
  if (productLevel.product) {
    if (!productLevel.product.selectedVariation) {
      if (productLevel.product.isHangingCloset) {
        heightObj.height = productLevel.product.hangingHeight - getTotalHeightBelowProductInConfiguration(configuration, productLevel.product.configurationId)
      } else {
        heightObj.height = productLevel.product.height
        heightObj.heightForHangingProducts = productLevel.product.height
      }
    } else {
      if (productLevel.product.isHangingCloset) heightObj.height = productLevel.product.hangingHeight - getTotalHeightBelowProductInConfiguration(configuration, productLevel.product.configurationId)
      else {
        heightObj.height = productLevel.product.selectedVariation.height ? productLevel.product.selectedVariation.height : productLevel.product.height;
        heightObj.heightForHangingProducts = productLevel.product.selectedVariation.height
      }
    }
  } else {
    if (!productLevel.productStacks) return 0;

    for (let i = 0; i < productLevel.productStacks.length; i++) {

      const stackHeight = getHeightFromStack(productLevel.productStacks[i], configuration);
      if (stackHeight.height > heightObj.height) heightObj.height = stackHeight.height;
      if (stackHeight.heightForHangingProducts > heightObj.heightForHangingProducts) heightObj.heightForHangingProducts = stackHeight.heightForHangingProducts;
    }
  }
  return heightObj;
}

export function getWidthForLevel(productLevel: ProductLevel): number {
  if (productLevel.product) return productLevel.product.width;
  let width = 0;
  if (productLevel.productStacks && productLevel.productStacks.length > 0) {
    productLevel.productStacks.forEach(stack => {
      width += getWidthForStack(stack);
    })
  }
  return width;
}

export function getTotalHeightBelowProductInProductLevel(productLevel: ProductLevel, productConfigurationId: string, calculateHeightBelowProduct: CalculateHeightBelowProduct): CalculateHeightBelowProduct {
  if (productLevel.product) {
    if (productLevel.product.configurationId === productConfigurationId) {
      calculateHeightBelowProduct.productFound = true
      return calculateHeightBelowProduct
    } else {
      calculateHeightBelowProduct.height += productLevel.product.selectedVariation.height ? productLevel.product.selectedVariation.height : productLevel.product.height
      return calculateHeightBelowProduct
    }
  }

  for (let i = 0; i < productLevel.productStacks?.length; i++) {
    const productStack = productLevel.productStacks[i];
    const newEenObj: CalculateHeightBelowProduct = {
      productFound: false,
      height: calculateHeightBelowProduct.height
    }
    const heightBelowProductInProductStack = getTotalHeightBelowProductInProductStack(productStack, productConfigurationId, newEenObj);
    if (heightBelowProductInProductStack?.productFound) return heightBelowProductInProductStack
  }

  return calculateHeightBelowProduct;
}

export function checkIfAllProductsHaveSameColorInProductLevel(productLevel: ProductLevel, color: ColorProperty): boolean {
  if (productLevel.product) return productLevel.product.selectedVariation.colorProperty.id === color.id;

  for (let i = 0; i < productLevel.productStacks?.length; i++) {
    const isSameColor = checkIfAllProductsHaveSameColorInProductStack(productLevel.productStacks[i], color);
    if (isSameColor === false) return false;
  }
  return true;
}
