import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ModalService} from "../../../shared/modal/modal.service";
import {ModalIdRequiredError} from "../../../shared/custom-errors/modal-id-required.error";
import {Select, Store} from "@ngxs/store";
import {ProductState} from "../../../products/product.state";
import {Observable, Subscription} from "rxjs";
import {Product} from "../../../products/models/product";
import {
  FetchAllCategories,
  FetchAllProducts,
  GetNumberOfProductsForEachCategory
} from "../../../products/product.actions";
import {AddProductToConfiguration} from "../../configurator.actions";
import {ProductLevel} from "../../models/product-level";
import {ProductOptions} from "../product-options-modal/product-options-modal.component";
import {ProductStack} from "../../models/product-stack";
import {v4 as uuid} from "uuid";
import {PopUpService} from "../../../shared/pop-up/pop-up.service";
import {PopUp} from "../../../shared/pop-up/models/pop-up";
import PopUpType from "../../../shared/pop-up/models/pop-up-type";
import PopUpMessage from "../../../shared/pop-up/models/pop-up-message";
import {Side} from "../../models/side";
import {getRemainingSpaceForLevel} from "../../models/configuration";
import {SearchBarComponent} from "../../../shared/components/search-bar/search-bar.component";
import Color from "../../../products/models/colors";
import {Category} from "../../../products/models/category";
import {ConfiguratorState} from "../../configurator.state";

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss']
})
export class AddProductModalComponent implements OnInit, OnDestroy {
  @Input() modalId: string;
  @Input() stack: ProductStack | undefined;
  @Input() level: ProductLevel | undefined;
  @Input() side: Side | undefined;
  @Input() totalStackHeightWithoutHangingProducts: number;
  @Input() isOpen: boolean = false;

  @Select(ProductState.products) products$: Observable<Product[]>;
  @Select(ProductState.categories) categories$: Observable<Category[]>;
  @Select(ProductState.widestProductWidth) widestProductWidth$: Observable<number>;

  @Select(ConfiguratorState.selectedCategory) selectedCategory$: Observable<Category>;
  selectedCategory: Category;

  @ViewChild('searchBarComponent') searchBar: SearchBarComponent;

  widestProductWidth: number;

  products: Product[];
  categories: Category[];

  searchKeyword: string = '';
  selectedColor: Color = undefined;

  fetchedProducts: boolean = false;
  fetchedCategories: boolean = false;

  productOptionsModalId: string = '';
  selectedProduct: Product;
  sideNeeded: boolean;

  productsRequestFailed: boolean = false;
  categoriesRequestFailed: boolean = false;

  protected readonly Color = Color;
  private subscriptions = new Subscription();

  constructor(
    private modalService: ModalService,
    private store: Store,
    private popUpService: PopUpService,
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.modalId) throw new ModalIdRequiredError();

    this.fetchData();
    this.productOptionsModalId = `addProductModal-productOptions-${uuid().split('-')[0]}`;
  }

  closeModal() {
    this.modalService.close(this.modalId);
  }

  focusOnSearchbar() {
    this.searchBar?.focus();
  }

  onProductClick(product: Product) {
    this.selectedProduct = product;

    if (!this.stack && product.variations && product.variations.length > 1) {
      // The product has variations, this means the user needs to select which variation he wants.
      this.modalService.open(this.productOptionsModalId)
      return
    } else if (this.stack && !this.level) {
      // A product is added to a new level, if the products width is smaller than the product where it is stacked on,
      // the user will be asked to choose a side where the product needs to be aligned on.
      // When the product has different variations, the user will be prompted to choose the desired variation
      const newProductHasSameWidthAsPreviousLevel = this.newProductHasSameWidthAsPreviousLevel(product);
      if (product.isHangingCloset || !newProductHasSameWidthAsPreviousLevel || (product.variations && product.variations.length > 0)) {
        this.sideNeeded = !newProductHasSameWidthAsPreviousLevel;
        this.modalService.open(this.productOptionsModalId)
        return;
      }
      this.store.dispatch(new AddProductToConfiguration(this.selectedProduct, this.totalStackHeightWithoutHangingProducts, this.side, this.stack.id))
      return;
    } else if (this.stack && this.level) {
      // A product is added to an existing level, the user will be asked to choose a side where the product needs to be aligned on.
      // When the product has different variations, the user will be prompted to choose the desired variation
      if (getRemainingSpaceForLevel(this.stack) > product.width) this.sideNeeded = true;
      if (product.isHangingCloset || this.sideNeeded || (product.variations && product.variations.length > 0)) this.modalService.open(this.productOptionsModalId)
      else this.store.dispatch(new AddProductToConfiguration(this.selectedProduct, this.totalStackHeightWithoutHangingProducts, this.side, this.stack.id, this.level.id, Side.LEFT))
      return;
    } else if (product.isHangingCloset) {
      // If the product is a hanging closet, the user will be prompted to add the hanging height of the product
      this.sideNeeded = false;
      this.modalService.open(this.productOptionsModalId);
      return;
    } else if (product.alternativeImgUrl) {
      this.sideNeeded = false;
      this.modalService.open(this.productOptionsModalId);
      return;
    }
    // If not, the product is added to the configuration
    this.store.dispatch(new AddProductToConfiguration(this.selectedProduct, this.totalStackHeightWithoutHangingProducts, this.side))
    this.closeModal()

  }

  addProduct(productOptions: ProductOptions) {
    // After the productOptions are chosen, the product is added to the configuration
    this.store.dispatch(new AddProductToConfiguration(this.selectedProduct, this.totalStackHeightWithoutHangingProducts, this.side, this.stack ? this.stack.id : '', this.level?.id, productOptions.side, productOptions.variation, productOptions.height, productOptions.imgUrl, productOptions.assembleSide));
    this.closeModal();
  }

  showMessageWhenProductsAreFiltered(): boolean {
    // Show a message to the user if the products in the list are filtered, only products that are or even wide as the product below in the stack are shown
    if (!this.stack) return false;
    const lastProduct: Product | undefined = this.stack.productLevels[this.stack.productLevels.length - 1].product
    if (!lastProduct) return false;
    return lastProduct.width < this.widestProductWidth;
  }

  reloadProducts() {
    this.subscriptions.add(
      this.store.dispatch(new FetchAllProducts())
        .subscribe({
          next: () => {
            this.productsRequestFailed = false;
            this.popUpService.add(new PopUp(PopUpType.SUCCESS, 'De producten zijn nu zichtbaar in de lijst.', 'Gelukt!'))
          },
          error: (err) => {
            this.productsRequestFailed = true;
            this.popUpService.add(new PopUp(PopUpType.ERROR, PopUpMessage.LOADING_PRODUCTS_PROBLEM.message, 'Woeps, iets is misgelopen...'))
          }
        }));
  }

  reloadCategories() {
    this.subscriptions.add(
      this.store.dispatch(new FetchAllCategories())
        .subscribe({
          next: () => {
            this.categoriesRequestFailed = false;
            this.popUpService.add(new PopUp(PopUpType.SUCCESS, 'De categorieën zijn nu zichtbaar in de lijst.', 'Gelukt!'))
          },
          error: (err) => {
            this.categoriesRequestFailed = true;
            this.popUpService.add(new PopUp(PopUpType.ERROR, PopUpMessage.LOADING_CATEGORIES_PROBLEM.message, 'Woeps, iets is misgelopen...'))
          }
        }));
  }

  updateSelectedColor(color: Color) {
    this.selectedColor = color;
  }

  private newProductHasSameWidthAsPreviousLevel(product: Product) {
    if (!this.stack) return false;
    const lastProduct: Product | undefined = this.stack.productLevels[this.stack.productLevels.length - 1].product
    if (!lastProduct) return false;
    return product.width === lastProduct.width;
  }

  private fetchData(): void {
    this.subscriptions.add(this.products$.subscribe((products: Product[]) => {
      this.products = products;

      if (!products || products.length < 1 && !this.fetchedProducts) {
        this.fetchedProducts = true;
        this.subscriptions.add(
          this.store.dispatch(new FetchAllProducts())
            .subscribe({
              next: () => {
                this.productsRequestFailed = false;
              },
              error: (err) => {
                this.productsRequestFailed = true;
                this.popUpService.add(new PopUp(PopUpType.ERROR, PopUpMessage.LOADING_PRODUCTS_PROBLEM.message, 'Woeps, iets is misgelopen...'))
              }
            }));
        return;
      }
    }));

    this.subscriptions.add(this.categories$.subscribe((categories: Category[]) => {
      this.categories = categories;

      if (!categories || categories.length < 1 && !this.fetchedCategories) {
        this.fetchedCategories = true;
        this.subscriptions.add(
          this.store.dispatch(new FetchAllCategories())
            .subscribe({
              next: () => {
                this.categoriesRequestFailed = false;
              },
              error: (err) => {
                this.categoriesRequestFailed = true;
                this.popUpService.add(new PopUp(PopUpType.ERROR, PopUpMessage.LOADING_CATEGORIES_PROBLEM.message, 'Woeps, iets is misgelopen...'))
              }
            })
        )
      }
    }))

    this.subscriptions.add(this.widestProductWidth$.subscribe((width) => {
      this.widestProductWidth = width;
    }))

    this.subscriptions.add(this.selectedCategory$.subscribe((c) => this.selectedCategory = c))
  }
}
